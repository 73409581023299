

// 查询今天代取收入
import Storage from "@/util/Storage";
import {getExpDayIncome} from "@/Api/Platform/exp";
import {getShopDayIncome} from "@/Api/Platform/shop";
import moment from "moment";

export function getExpIncome(body :any= {}) {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");
    const day = moment().format("YYYY-MM-DD"),startDate = day,endDate = day;
    if ( JSON.stringify(body) == "{}" ) body = { startDate,endDate };
    return Promise.resolve(getExpDayIncome({ userId,token },body).then(res=>{
        return res.data
    }))
}

// 查询今天外卖收入
export function getShopIncome(body:any = {}) {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");
    const day = moment().format("YYYY-MM-DD"),startDate = day,endDate = day;
    if ( JSON.stringify(body) == "{}" ) body = { startDate,endDate };
    return Promise.resolve(getShopDayIncome({ userId,token },body).then(res=>{
        return res.data
    }))
}
