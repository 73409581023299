
// 代取
export const ExpTitleList = [
    {
        width:"26",
        value:"学校",
        after:true
    },{
        width:"18.5",
        value:"代取单量",
        after:true
    },{
        width:"18.5",
        value:"总付款",
        after:true
    },{
        width:"18.5",
        value:"校区收益",
        after:true
    },{
        width:"18.5",
        value:"平台收益",
        after:true
    },{
        width:"18.5",
        value:"微信抽成",
        after:false
    },
]

// 外卖
export const ShopTitleList = [
    {
        width:"26",
        value:"学校",
        after:true
    },{
        width:"18.5",
        value:"外卖单量",
        after:true
    },{
        width:"18.5",
        value:"总付款",
        after:true
    },{
        width:"18.5",
        value:"店铺收益",
        after:true
    },{
        width:"18.5",
        value:"校区收益",
        after:true
    },{
        width:"18.5",
        value:"平台收益",
        after:true
    },{
        width:"18.5",
        value:"微信抽成",
        after:false
    },
]

export const TitleList = (state="exp")=> state=="exp" ? ExpTitleList : ShopTitleList;

export const testList = [
    [
        {
            value:"无",
        },{
            value:"0",
        },{
            value:"0",
        },{
            value:"0",
        },{
            value:"0",
        },
    ]
]
