import Storage from "@/util/Storage";
import {bindingJson, filterToArray} from "@/views/PlatformAdmin/Util";
import {getExpSchoolIncome} from "@/Api/Platform/exp";
import {getShopSchoolIncome} from "@/Api/Platform/shop";
import { getExpIncome as findExpIncome,getShopIncome as findShopIncome } from "@/views/PlatformAdmin/Server"

// 代取平台收益
export function getExpIncome(body:any = {}) {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");
    return Promise.resolve(getExpSchoolIncome({ userId,token },body).then(res=>{
        return {
            rawList:res.data,
            list:filterToArray(res.data,"0","schoolName","orderCount","amount","schoolFee","serviceFee","weixinFee")
        }
    }))
}

// 外卖平台收益
export function getShopIncome(body:any = {}) {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");
    return Promise.resolve(getShopSchoolIncome({ userId,token },body).then(res=>{
        return {
            rawList:res.data,
            list:filterToArray(res.data,"无","schoolName","orderCount","income","storeFee","schoolFee","serviceFee","weixinFee")
        }
    }))
}

// 根据时间查询具体 订单量及收益
export function getTimeIncome(startDate="",endDate="",state="exp") {
    // amount orderCount schoolFee serviceFee weixinFee storeFee
    if ( state == "exp" ){
        return Promise.resolve(findExpIncome({startDate,endDate}).then(res=>{
            return bindingJson(res,{ "amount":"amount","orderCount":"orderCount","schoolFee":"schoolFee","serviceFee":"serviceFee","weixinFee":"weixinFee" });
        }))
    }else{
        return Promise.resolve(findShopIncome({startDate,endDate}).then(res=>{
            return bindingJson(res,{ "income":"amount","orderCount":"orderCount","schoolFee":"schoolFee","serviceFee":"serviceFee","storeFee":"storeFee","weixinFee":"weixinFee" });
        }))
    }
}
