

import Axios from "../Config/exp"
import {HeadType, BodyType, getUserCountType, getSchoolMonthUserCountType} from "@/Api/Platform/interfaceType"

// 查询 日 收益
export function getExpDayIncome( headers:HeadType,body:getUserCountType={} ):Promise<any>{
    return (
        Axios({
            url:"/campus/income/dayIncome",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 查询 各校 收益
export function getExpSchoolIncome( headers:HeadType,body:getSchoolMonthUserCountType ):Promise<any>{
    return (
        Axios({
            url:"/campus/income/incomeBySchool",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 查询 月 收益
export function getExpSchoolMonthIncome( headers:HeadType,body:getSchoolMonthUserCountType ):Promise<any>{
    return (
        Axios({
            url:"/campus/income/everyMonthIncome",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 查询 具体日 收益
export function getExpSchoolDayIncome( headers:HeadType,body:getSchoolMonthUserCountType ):Promise<any>{
    return (
        Axios({
            url:"/campus/income/everyDayIncome",
            method:"POST",
            headers,
            data:body
        })
    )
}
