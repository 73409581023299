

import Axios from "../Config/shop"
import {HeadType, BodyType, getUserCountType, getSchoolMonthUserCountType} from "@/Api/Platform/interfaceType"

// 查询 日 收益
export function getShopDayIncome( headers:HeadType,body:getUserCountType={} ):Promise<any>{
    return (
        Axios({
            url:"/app/order/income/dateIncome",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 查询 学校 收益
export function getShopSchoolIncome( headers:HeadType,body:getSchoolMonthUserCountType ):Promise<any>{
    return (
        Axios({
            url:"/app/order/income/schoolIncomeList",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 查询 月 收益
export function getShopSchoolMonthIncome( headers:HeadType,body:getSchoolMonthUserCountType ):Promise<any>{
    return (
        Axios({
            url:"/app/order/income/monthIncome",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 查询 具体日 收益
export function getShopSchoolDayIncome( headers:HeadType,body:getSchoolMonthUserCountType ):Promise<any>{
    return (
        Axios({
            url:"/app/order/income/dayIncome",
            method:"POST",
            headers,
            data:body
        })
    )
}
