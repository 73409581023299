
export const startDate = 2018;

export function getYearList() {
    let date = new Date(),
        year = date.getFullYear(),
        List = [];
    for (let i = startDate; i <= year; i ++){
        List.push({
            text:i+" 年",
            value:i-startDate,
            year:i
        })
    }
    return List;
}
